<template>
    <div >
        <van-overlay :show="!!showList"  @click="showList=false" z-index="2"></van-overlay>
        <van-cell :border="border"
                  :title="label"
                  :title-style="{width:labelWidth||'50%', minWidth:labelWidth||'50%', maxWidth:labelWidth||'50%',marginRight:'var(--van-field-label-margin-right)'}"
                  value-class="field-value"
                  v-bind="$attrs"
                  :style="{zIndex:showList?3:1}" >
            <template v-if="$slots.label||label" #title>
                <slot name="label">
                    {{label}}
                </slot>
            </template>
            <template #value>
                <div @click="showList=!disabled">
                    <span v-if="!value" style="color:var(--van-field-placeholder-text-color)">
                    {{$attrs.placeholder||'select'}}</span>
                    <span v-else :style="error?'color:var(--van-danger-color)':''">
                        {{value?.[field]||value||'hello'}}
                    </span>
                </div>
                <div v-if="errorMessage" style="color:var(--van-danger-color);font-size:var(--van-field-error-message-font-size);">{{errorMessage}}</div>
            </template>
            <template #right-icon>
                <slot name="icon">
                    <van-icon v-if="clearable && value && showList" name="clear" class="van-field__clear" @click="value=null;showList=false"/>
                </slot>
            </template>
            <template v-if="!showList" #extra>
                <div @click="showList=true">
                    <slot name="extra"></slot>
                </div>
            </template>
        </van-cell>
        <van-cell-group v-if="showList"
                        inset :border="false"
                        style="z-index:3;max-height:40vh;overflow-y:scroll;position: absolute;left:0;right:0;margin-top: 5px;">
            <van-cell v-for="val in list" @click="onSelect(val)">
                <template #title>
                    <slot name="item" :value="val">
                        {{val[field]||val}}
                    </slot>
                </template>
            </van-cell>
        </van-cell-group>
        <div v-if="border" class="van-cell:after"></div>
    </div>

</template>

<script setup>

const props = defineProps({
    label: String,
    labelWidth: [String,Number],
    modelValue: [String,Object],
    clearable:Boolean,
    disabled:Boolean,
    list: Array,
    border: Boolean,
    field: String,
    error: Boolean,
    errorMessage: String
})
const emit = defineEmits(['update:modelValue'])
const show = ref(false)
const value = ref(props.modelValue)
const filteredList = computed(()=>{
    if(value.value?.length){
        return _filter(props.list,v=>{
            return _upperCase(v).indexOf(_upperCase(value.value))===0
        })
    } else return props.list
})
const showList = ref(false)
function onSelect(val){
    console.log('change on select',val)
    value.value = val
    emit('update:modelValue',val)
    showList.value = false
}
function onClear(val){
    emit('update:modelValue','')
}
watch(()=>props.modelValue,val=>{
    if(!val) value.value = null
})
function formatter(val){
    const formatted = val?.[props.field]||val
    console.log('formatted',val,formatted)
    return formatted
}

</script>

<style >
.field-value {
    text-align: left;color:var(--van-field-input-text-color);
}

</style>